export function createCloseSVG() {
  const close = document.createElementNS('http://www.w3.org/2000/svg', "svg");
  close.setAttribute('width', '20');
  close.setAttribute('height', '20');
  close.setAttribute('viewBox', '0 0 20 20');
  close.setAttribute('fill', 'none');
  close.setAttribute('stroke', 'none');

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('d', 'M9.85355 10.5607L19 19.7071L19.7071 19L10.5607 9.85355L19.7071 0.707108L19 0L9.85355 9.14645L0.707107 0L0 0.707107L9.14645 9.85355L0 19L0.707107 19.7071L9.85355 10.5607Z');
  path.setAttribute('fill-rule', 'evenodd');
  path.setAttribute('clip-rule', 'evenodd');
  path.setAttribute('fill', '#E2E8EF');

  close.appendChild(path);
  
  return close;
}