import { createCloseSVG } from "./utils";

interface IInitOptions {

}

interface IPayOptions {
  linkCode: string;
}

class ATPayWidget {
  options: IInitOptions;

  constructor (initOptions: IInitOptions) {
    if (!initOptions) {
      throw new Error ('Неправильно указаны опции инициализации платежного виджета');
    }

    this.options = initOptions;
  }

  pay(payOptions: IPayOptions) {
    if (!payOptions) {
      throw new Error ('Неправильно указаны настройки оплаты');
    }
  
    const rootDiv = document.createElement('div');
    rootDiv.style.cssText = "display: flex !important; justify-content: center !important; align-items: center !important; \
                            height: 100% !important; width: 100% !important; position: fixed !important; z-index: 99999 !important; \
                            border: 0px !important; inset: 0px !important; max-height: 100% !important; background: rgba(11, 30 , 70, 0.74) !important";
    document.body.appendChild(rootDiv);
  
    const relativeContainer = document.createElement('div');
    relativeContainer.style.cssText = "position: relative;";
    rootDiv.appendChild(relativeContainer);
  
    const iframe = document.createElement('iframe');
    iframe.style.cssText = "border: 0px !important; inset: 0px !important;overflow-x: hidden;";
    iframe.allow = 'payment same-origin';
    // Необходимо тут получать корректную ссылку на локальный сервер/дев/прод;
    iframe.src = `${process.env.REACT_APP_BASEPATH}/pay/${payOptions.linkCode}`;

    // При необходимости расширить условия определения размеров iframe
    // И вынести в utils
    if (window.innerWidth > 1280 * 1.2) {
      iframe.width = '1280';
      iframe.height = '720';
    } else if (window.innerWidth > 420 * 1.2) {
      iframe.width = '420';
      iframe.height = window.innerHeight > 800 ? '800' : String(window.innerHeight * 0.8);
    } else {
      iframe.width = String(window.innerWidth * 0.8);
      iframe.height = String (window.innerHeight * 0.8);
    }
    
    relativeContainer.appendChild(iframe);
    
    const close = createCloseSVG();
    const closeContainer = document.createElement('div');
    closeContainer.style.cssText = "position: absolute; right: -25px; top: -5px; cursor: pointer;";
    closeContainer.onclick = function () {
      document.body.removeChild(rootDiv);
    };
    closeContainer.appendChild(close);
    relativeContainer.appendChild(closeContainer);
  }
}

export {
  ATPayWidget
}